<template>
  <div class="block-box">
    <div class="block-title">
      <div class="block-title-left">学院排行</div>
      <div class="block-title-right">排序方式: <span @click="changeSort">
          <svg-icon icon-class="switch" /> {{sortName}}</span></div>
    </div>
    <div class="block-content rank-box">
      <div class="rank-chart" v-if="rankList.length">
        <div v-for="(item,idx) in rankList" :key="idx" class="college-item">
          <van-row>
            <van-col :span="7">
              <p class="college-name">{{idx+1}}. {{item.college}}</p>
            </van-col>
            <van-col :span="17">
              <div class="college-stats" :style="{width:item.progress+`%!important`}">
                <van-row>
                  <van-col :span="18">
                    <div class="college-progress"></div>
                  </van-col>
                  <van-col :span="6">
                    <p class="college-value">{{item.value}}{{unit}}</p>
                  </van-col>
                </van-row>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <div v-else>
        <p class="college-rank-no-data">暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCollegeRank } from '@/api/donate'

export default {
  props: {
    pid: Number
  },
  data () {
    return {
      rankList: [],
      sortKey: 'amount',
      sortName: '捐赠金额',
      unit: '元'
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getCollegeRank(this.pid, this.sortKey).then(response => {
        this.rankList = response
      })
    },
    changeSort () {
      if (this.sortKey === 'amount') {
        this.sortKey = 'member'
        this.sortName = '捐赠次数'
        this.unit = '次'
        this.fetchData()
        return
      }
      if (this.sortKey === 'member') {
        this.sortKey = 'amount'
        this.sortName = '捐赠金额'
        this.unit = '元'
        this.fetchData()
      }
    }
  }
}
</script>

<style>
.rank-box {
  height: 10rem;
  overflow-x: scroll;
}

.college-item {
  height: 1.5rem;
  margin: 0.2rem 0;
}

.college-name,
.college-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  font-size: 0.8rem;
  line-height: 1.5rem;
}
.college-stats {
  height: 1.5rem;
}
.college-progress {
  background-color: red;
  height: 1.5rem;
}

.college-value {
  overflow:visible;
  margin-left: 0.4rem;
}

.college-rank-no-data{
  text-align: center;
  font-size: .8rem;
  margin-top: 4rem;
}
</style>
