<template>
  <div class="page-container">
    <van-tabs v-model="currentTab" sticky @click="handleTabChange">
      <van-tab title="项目详情" name="project">
        <div class="detail-media">
          <vue-danmaku
            class="danmu-box"
            ref="danmu"
            :danmus="danmusList"
            autoplay
            loop
            :speed="10"
            :debounce="200"
            :top="25"
            :right="200"
            randomChannel
            :channels="5"
            useSlot
          >
            <template slot="dm" slot-scope="{ index, danmu }">
              <div class="danmu-item" :class="`danmu${index}`">
                <img class="danmu-item-avatar" :src="danmu.avatar" alt="" />
                <span
                  class="danmu-item-text"
                  :style="`background-color:${danmu.color}`"
                  >{{ danmu.message }}</span
                >
              </div>
            </template>
            <img v-lazy="item.media.url" alt="media" />
            <div class="danmu-switch" @click="handleDanmuSwitch">
              <svg-icon icon-class="credit"  v-if="danmuSwitch"/>
              <svg-icon icon-class="credit-off"  v-else/>
            </div>
          </vue-danmaku>
        </div>
        <div class="detail-box">
          <p class="detail-meta-title">{{ item.title }}</p>
        </div>
        <div class="info-box">
          <van-row>
            <van-col :span="7" :offset="1">
              <svg-icon icon-class="no" /> {{ item.number }}</van-col
            >
            <van-col :span="5">
              <svg-icon icon-class="eye-open" /> {{ item.view }}</van-col
            >
            <van-col :span="5">
              <svg-icon icon-class="date" /> {{ item.time }}</van-col
            >
            <van-col :span="3" :offset="2"
              ><span @click="showShareGuide = true">
                <svg-icon icon-class="share" /> 分享</span
              ></van-col
            >
          </van-row>
        </div>

        <donate-stats
          :rate="stats.rate"
          :amount="stats.amount"
          :aim="stats.aim"
          :stop="stats.stop"
          :count="stats.count"
        />
        <template v-if="item.showCollegeRank">
          <donate-rank v-if="pid" :pid="pid" />
        </template>
        <template v-if="item.showDonateRank">
          <donate-message
            v-if="personRankList"
            :person="personRankList"
            :group="groupRankList"
          />
        </template>
        <template v-if="item.showLocation">
          <map-block
            :x="item.location.x"
            :y="item.location.y"
            :title="item.location.title"
          />
        </template>
        <donate-content :content="articleContent" />
        <navigate-wxmp
          v-if="showAd"
          :username="adData.link"
          :path="adData.param"
        >
          <img :src="adData.image" width="100%" alt="" />
        </navigate-wxmp>
        <sdut-banner />
      </van-tab>
      <van-tab title="项目进展" name="status">
        <donate-dynamic :pid="pid" />
        <sdut-banner />
      </van-tab>
      <van-tab title="捐赠记录" name="record">
        <div class="donate-record">
          <donate-person-list :pid="pid" />
        </div>
        <div style="margin-bottom: 3.5rem"></div>
      </van-tab>
      <van-tab title="捐赠团队" name="group">
        <div class="donate-group-list">
          <donate-group-list :pid="pid" />
        </div>
        <div style="margin-bottom: 3.5rem"></div>
      </van-tab>
    </van-tabs>
    <div class="donate-tool">
      <van-row>
        <van-col span="3">
          <van-button
            style="width: 100%; border: none"
            type="default"
            to="/project"
          >
            <template #default>
              <svg-icon icon-class="home" class-name="tool-button-icon" />
            </template>
          </van-button>
        </van-col>
        <van-col span="3">
          <van-button
            @click="handleStarProject"
            style="width: 100%; border: none"
            type="default"
          >
            <template #default>
              <svg-icon
                v-if="item.star"
                icon-class="stared"
                class-name="tool-button-icon"
              />
              <svg-icon
                v-else
                icon-class="star"
                class-name="tool-button-icon"
              />
            </template>
          </van-button>
        </van-col>
        <van-col span="18">
          <van-button
            v-if="enable"
            style="width: 90%; margin: 0 auto"
            type="primary"
            round
            color="#FF0000"
            @click="showDonateSelecter = !showDonateSelecter"
            >我要捐赠</van-button
          >
          <van-button
            v-else
            style="width: 90%; margin: 0 auto"
            type="primary"
            round
            color="#FF0000"
            plain
            disabled
            >{{ status }}</van-button
          >
        </van-col>
      </van-row>
    </div>
    <donate-selecter
      v-if="pid"
      :show.sync="showDonateSelecter"
      :project="pid"
    />
    <share-guide v-model="showShareGuide" :title="item.title" />
  </div>
</template>

<script>
import { Notify } from "vant";
import SdutBanner from "@/components/SdutBanner";
import DonateGroupList from "@/components/DonateGroupList";
import DonateDynamic from "@/components/DonateDynamic";
import DonateRank from "@/components/DonateRank";
import DonateStats from "@/components/DonateStats";
import DonateContent from "@/components/DonateContent";
import DonateMessage from "@/components/DonateMessage";
import DonatePersonList from "@/components/DonatePersonList";
import ShareGuide from "@/components/ShareGuide";
import { getProjectDetail, starProject, unstarProject } from "@/api/project";
import { getDonateRank } from "@/api/donate";
import wxSdk from "@/utils/wxsdk";
import { getStorage } from "@/utils/storage";
import DonateSelecter from "@/components/DonateSelecter";
import MapBlock from "@/components/MapBlock";
import { arrayToQuery } from "@/utils/encode";
import NavigateWxmp from "@/components/NavigateWxmp";
import vueDanmaku from "vue-danmaku";
import { getProjectDanmu } from "@/api/project";

export default {
  name: "DonateDetail",
  components: {
    SdutBanner,
    DonateGroupList,
    DonateDynamic,
    DonateRank,
    DonateStats,
    DonateContent,
    DonateMessage,
    DonatePersonList,
    ShareGuide,
    DonateSelecter,
    MapBlock,
    NavigateWxmp,
    vueDanmaku,
  },
  data() {
    return {
      pid: null,
      currentTab: "project",
      enable: true,
      staus: null,
      showShareGuide: false,
      item: {
        title: null,
        view: null,
        time: null,
        number: null,
        media: {
          type: "image",
          url: null,
        },
        selecter: [
          { title: null, amount: 0, ref: "template1" },
          { title: null, amount: 0, ref: "template2" },
          { title: null, amount: 0, ref: "template3" },
        ],
        random: [],
        rate: 0,
        star: false,
        showLocation: false,
        location: {},
        showCollegeRank: false,
        showDonateRank: false,
      },
      stats: {
        aim: 0,
        amount: 0,
        count: 0,
        stop: null,
        rate: 0,
      },
      showDonateSelecter: false,
      inputAmount: 0,
      annoymousDonate: false,
      readNotice: true,
      articleLoading: true,
      articleContent: null,
      inviteCol: [],
      rankData: [],
      barrageList: [],
      currentId: 0,
      personRankList: null,
      groupRankList: null,
      lastTab: null,
      amountDisplay: null,
      userId: null,
      showAd: false,
      adData: {},
      danmusList: [],
      danmuSwitch: true,
    };
  },
  watch: {
    $route(to, from) {
      this.handleJssdkShare();
      if (to.params.tab !== this.currentTab) {
        this.currentTab = to.params.tab;
      }
    },
  },
  mounted() {
    this.currentTab = this.$route.params.tab || "project";
    this.pid = parseInt(this.$route.params.pid);
    this.fetchData();
    this.loadDanmu();
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "DonateForm") {
      to.meta.keepAlive = false;
    }
    next();
  },
  methods: {
    fetchData() {
      this.fetchProjectDetail();
      this.fetchDonateRank();
    },
    fetchProjectDetail() {
      getProjectDetail(this.pid).then((response) => {
        this.enable = response.enable;
        this.status = response.status;
        this.userId = response.user;

        document.title = response.title;
        this.item = {
          title: response.title,
          star: response.star,
          number: response.number,
          time: response.time,
          view: response.view,
          media: {
            type: response.media_type,
            url: response.media_url,
          },
          selecter: [
            {
              title: response.selecter[0]["name"],
              amount: response.selecter[0]["value"],
              ref: "template1",
            },
            {
              title: response.selecter[1]["name"],
              amount: response.selecter[1]["value"],
              ref: "template2",
            },
            {
              title: response.selecter[2]["name"],
              amount: response.selecter[2]["value"],
              ref: "template3",
            },
          ],
          random: response.random,
          rate: response.progress,
        };
        if (response.show_location) {
          this.item.showLocation = response.show_location;
          this.item.location = response.location;
        }
        if (response.show_college_rank) {
          this.item.showCollegeRank = true;
        }
        if (response.show_donate_rank) {
          this.item.showDonateRank = true;
        }
        this.stats = {
          aim: response.aim,
          amount: response.amount,
          count: response.count,
          stop: response.stop,
          rate: response.progress,
        };
        this.articleContent = response.content;

        this.showAd = response.show_ad;
        if (this.showAd) {
          this.adData = response.ad;
        }

        this.handleJssdkShare();
      });
    },
    fetchDonateRank() {
      getDonateRank(this.pid).then((response) => {
        this.personRankList = response.person;
        this.groupRankList = response.group;
      });
    },
    handleTabChange(tab) {
      if (this.lastTab === tab) {
        return;
      }
      this.lastTab = tab;
      let path = `/project/${this.pid}/${tab}`;
      let route_query = this.$route.query;
      if (this.$route.query.group) {
        route_query.group = this.$route.query.group;
      }
      if (this.$route.query.inviter) {
        route_query.inviter = this.$route.query.inviter;
      }
      let query_str = arrayToQuery(route_query);
      if (query_str.length) {
        path += `?${query_str}`;
      }
      this.$router.push({ path: path });
    },
    changeSelecter(ref) {
      // TODO: 这是写的啥玩意
      let selecterList = [];
      let selecterValue = [];
      this.item.selecter.forEach((selecter) => {
        selecterList.push(selecter.ref);
        selecterValue.push(selecter.amount);
      });
      selecterList.forEach((item, index) => {
        let style = this.$refs[item][0].style;
        if (item === ref) {
          style.color = "red";
          style.borderColor = "red";
          this.inputAmount = selecterValue[index];
        } else {
          style.color = "#323233";
          style.borderColor = "#2c3e50";
        }
      });
    },
    randomAmount() {
      this.inputAmount = this.item.random[
        Math.floor(Math.random() * this.item.random.length)
      ];
    },
    nextStepAction() {
      if (this.inputAmount === 0) {
        Notify({ type: "warning", message: "请选择或填写捐赠金额" });
        return;
      }
      if (!this.readNotice) {
        Notify({ type: "warning", message: "请阅读捐赠协议" });
        return;
      }
      if (this.inputAmount <= 0) {
        Notify({ type: "warning", message: "捐赠金额无效" });
        return;
      }
      let donate = {
        pid: this.pid,
        amount: this.inputAmount,
        annoymous: this.annoymousDonate,
        group: this.$route.query.group,
        inviter: this.$route.query.inviter,
      };
      this.$router.push({
        name: "DonateForm",
        query: donate,
      });
    },
    handleStarProject() {
      if (this.item.star) {
        unstarProject(this.pid).then((response) => {
          this.item.star = response.star;
        });
      } else {
        starProject(this.pid).then((response) => {
          this.item.star = response.star;
        });
      }
    },
    changeTab(to) {
      this.currentTab = to;
    },
    handleJssdkShare() {
      document.title = this.item.title;
      let share_route = this.$router.resolve({
        name: "ProjectDetail",
        params: { pid: this.pid, tab: "project" },
        query: { inviter: this.userId },
      });
      let share_url =
        document.location.protocol +
        "//" +
        document.location.host +
        share_route.href;

      wxSdk.share(
        this.item.title,
        getStorage("name") + "邀请您查看该项目",
        share_url
      );
    },
    loadDanmu() {
      getProjectDanmu(this.pid).then((response) => {
        this.danmusList = response;
      });
      //this.$refs['danmu'].start()
    },
    handleDanmuSwitch() {
      this.danmuSwitch = !this.danmuSwitch;
      this.danmuSwitch
        ? this.$refs["danmu"].play()
        : this.$refs["danmu"].stop();
    },
  },
};
</script>

<style>
.detail-media {
  width: 100%;
  height: 15rem;
}

.detail-media img {
  width: 100%;
  height: 100%;
}

.donate-tool {
  width: 100%;
  height: 2.7rem;
  box-shadow: 0 0 0.05rem 0 rgba(34, 42, 63, 0.5);
  background-color: #fff;
  position: fixed;
  bottom: 0;
}

.no-border {
  border: none;
}

.donate-selecter {
  height: 20rem;
}

.donate-selecter-container {
  height: 16.2rem;
  padding: 0.5rem;
}

.donate-info {
  height: 6rem;
  display: flex;
}

.donate-info-img {
  float: left;
}

.donate-info-img img {
  height: 6rem;
  width: 10rem;
  border: 0.03rem solid #eaeef0;
  border-radius: 0.3rem;
}

.donate-info-text {
  float: left;
  margin: 0.5rem;
  flex: 1;
}

.donate-info-text p {
  margin: 0;
  text-align: left;
}

.next-step {
  width: 100%;
  bottom: 0;
}
/*
.donate-selecter-item {
  height: 2rem;
  margin: 0.2rem 0;
  text-align: center;
}

.donate-selecter-item span {
  border: #2c3e50 0.08rem solid;
  border-radius: 0.3rem;
  padding: 0.3rem 2.3rem;
  font-size: 1rem;
}

.donate-selecter-input {
  border: #2c3e50 0.08rem solid;
  border-radius: 0.3rem;
  font-size: 1rem;
  height: 2rem;
  padding: 0.2rem;
  margin: 0.2rem 0.54rem;
}

.donate-selecter-random {
  line-height: 2rem;
  margin: 0.2rem 0;
  padding-left: 1rem;
  font-size: 1rem;
}
*/

.donate-info-title {
  font-size: 1.1rem;
  font-weight: bold;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.donate-info-amount {
  margin-top: 1rem !important;
  font-size: 2rem;
  color: red;
}

.detail-article {
  padding: 0 0.7rem;
  background-color: #ffffff;
}

.detail-box {
  background-color: #ffffff;
  padding: 0.5rem;
  margin-top: 0.2rem;
}

.detail-meta {
}

.detail-meta-title {
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  line-height: 3rem;
}

h3 {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

.donate-record {
}

.baberrage-stage {
  width: 100%;
  height: 15rem !important;
  z-index: 999;
  position: relative;
}

.tool-button-icon {
  font-size: 1.2rem;
}

.info-box {
  width: 100%;
  height: 1rem;
  background-color: #ffffff;
  font-size: 0.7rem;
}

.danmu-box {
  width: 100%;
  height: 100%;
}

.danmu-item-avatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
  float: left;
  border-radius: 50%;
}

.danmu-item {
  opacity: 0.8;
}

.danmu-item-text {
  font-size: 0.8rem;
  padding: 0.1rem;
  height: 1.2rem;
  line-height: 1.2rem;
  border-radius: 3px;
}

.danmu-switch{
  position: absolute;
  bottom:0;
  right: 0;
  width: 2rem;
  height: 2rem;
  font-size: 1.3rem;
}
</style>
