<template>
  <div class="dynamic-list">
    <van-pull-refresh v-model="isLoading" @refresh="handleRefresh">
      <timeline>
        <timeline-title>{{title}}</timeline-title>
        <timeline-item bg-color="#FF0000" v-for="(item,idx) in list" :key="idx">
          <div class="dynamic-item">
            <div class="dynamic-title">
              <p>{{item.title}}</p>
            </div>
            <div class="dynamic-content" v-if="item.content" v-html="item.content">
            </div>
            <div class="dynamic-time">
              <p>{{item.time}}</p>
            </div>
          </div>
        </timeline-item>
      </timeline>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getProjectDynamic } from '@/api/project'
import { Timeline, TimelineItem, TimelineTitle } from 'vue-cute-timeline'
export default {
  components: { Timeline, TimelineItem, TimelineTitle },
  props: {
    pid: Number
  },
  data () {
    return {
      list: [],
      title: null,
      isLoading: true
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      getProjectDynamic(this.pid).then(response => {
        this.list = response.list
        this.title = response.title
        this.isLoading = false
      })
    },
    handleRefresh () {
      this.fetchData()
    }
  }
}
</script>

<style>
.dynamic-list {
  width: 100%;
  min-height: 20rem;
  background-color: #ffffff;
}

.dynamic-list > .timeline {
  margin-top: 0 !important;
}
.dynamic-list > .timeline > .timeline-item {
  padding-bottom: 0.5rem !important;
  margin-top: 1rem !important;
}
.dynamic-title {
  width: 100%;
  margin-bottom: 0.3rem;
}

.dynamic-item {
  padding: 0;
}

.dynamic-item p {
  margin: 0;
}

.dynamic-title > p {
  width: 100%;
  font-size: 0.9rem;
  line-height: 1.6rem;
}

.dynamic-time > p {
  width: 4rem;
  font-size: 0.5rem;
  line-height: 1.5rem;
}

.dynamic-content {
  background-color: #efefef;
  min-height: 2rem;
  padding: 0.5rem;
}
</style>
