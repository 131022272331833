<template>
  <div class="block-box">
    <div class="block-title">
      <div class="block-title-left">项目描述</div>
    </div>
    <div class="block-content description-box">
      <article class="article-content" v-html="this.content"></article>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default () {
        return null
      }
    }
  }
}
</script>

<style>
.description-box {
  padding: 0 1rem;
}

.description-box > article {
  text-indent: 1rem;
  line-height: 1.5rem;
}
</style>
