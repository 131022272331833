<template>
  <div class="group-box">
    <div class="group-stats">
      <van-row>
        <van-col span="12">
          <p class="group-count">
            <svg-icon icon-class="group" /> 共有捐赠团队<span
              class="red-text"
              >{{ groupCount }}</span
            >个
          </p>
        </van-col>
        <van-col span="12">
          <van-button
            icon="plus"
            type="primary"
            color="red"
            plain
            class="group-add"
            :to="{ path: '/newgroup?project=' + pid }"
            >创建捐赠团队</van-button
          >
        </van-col>
      </van-row>
    </div>
    <div class="group-list">
      <van-pull-refresh v-model="isLoading" @refresh="handleRefresh">
        <donate-group-item
          v-for="(item, idx) in groups"
          :key="idx"
          :group="item.id"
          :name="item.name"
          :amount="item.amount"
          :member="item.member"
          :relation="item.relation"
          :leader="item.leader"
          :message="item.message"
          :avatar="item.avatar"
        />
      </van-pull-refresh>
    </div>
    <div class="dgl-more">
      <div v-if="!over" style="text-align: center">
        <van-button
          @click="loadMore"
          type="primary"
          size="mini"
          color="#969799"
          round
          plain
          >加载更多</van-button
        >
      </div>
      <van-divider v-else>{{
        isLoading ? "加载中..." : "已加载全部"
      }}</van-divider>
    </div>
  </div>
</template>

<script>
import DonateGroupItem from "@/components/DonateGroupItem";
import { getGroupList } from "@/api/group";
export default {
  components: { DonateGroupItem },
  props: {
    pid: Number,
  },
  data() {
    return {
      groups: [],
      isLoading: true,
      over: true,
      groupCount: 0,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getGroupList(this.pid).then((response) => {
        this.groups = response;
        this.isLoading = false;
        this.groupCount = this.groups.length;
      });
    },
    handleRefresh() {
      this.fetchData();
    },
    loadMore() {
      console.log("load more");
    },
  },
};
</script>

<style>
.group-stats {
  height: 1.5rem;
  background-color: #ffffff;
  padding: 0 1.5rem;
  margin-bottom: 0.1rem;
}

.group-count {
  margin: 0;
  line-height: 1.5rem;
}

.group-add {
  height: 1.5rem !important;
  float: right;
}

.dgl-more {
  margin-top: 1rem;
}
</style>
