<template>
  <div class="block-box">
    <div class="block-title">
      <div class="block-title-left">项目进度</div>
    </div>
    <div class="block-content">
      <template v-if="mode === 1">
        <div class="stats-box">
          <van-row>
            <van-col span="8">
              <van-circle
                v-model="progress"
                :rate="rate"
                :stroke-width="100"
                color="#ff0000"
                layer-color="#f7f7f7"
                :text="rate + `%`"
              />
            </van-col>
            <van-col span="14">
              <ul class="stats-table">
                <!--li>筹款目标: {{aim}}</li-->
                <li>已筹捐款: {{ amount }}</li>
                <li>参与次数: {{ count }}</li>
                <!--li>截止日期: {{stop}}</li-->
              </ul>
            </van-col>
            <van-col span="2">
              <p class="stats-more">
                <svg-icon icon-class="point-right" />
              </p>
            </van-col>
          </van-row>
        </div>
      </template>
      <template v-else>
        <div class="stats-box-e">
          <van-row gutter="20">
            <van-col span="8" offset="4">
              <div class="stats-gird">
                <div class="stats-gird-number">{{ count }}</div>
                <div class="stats-gird-text">参与次数/次</div>
              </div>
            </van-col>
            <van-col span="8">
              <div class="stats-gird">
                <div class="stats-gird-number">
                  {{ amountStr?amountStr:amount }}
                </div>
                <div class="stats-gird-text">捐赠金额/元</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatNumber } from "@/utils/money";
export default {
  props: {
    rate: Number,
    aim: Number,
    amount: Number,
    count: Number,
    stop: String,
    mode: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      progress: 0,
      amountStr: null,
    };
  },
  watch: {
    amount(newVal, oldVal) {
      this.amountStr = formatNumber(newVal);
    },
  },
};
</script>

<style>
.stats-box {
  height: 6.3rem;
  padding: 0.2rem;
}

.stats-box > div {
  text-align: center;
}
.stats-more {
  line-height: 3rem;
}

.stats-table {
  font-size: 0.8rem;
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.stats-table li {
  text-align: left;
  line-height: 1.3rem;
}

.stats-gird {
  text-align: center;
}

.stats-gird-number {
  font-size: 1.2rem;
  font-weight: bold;
}

.stats-gird-text {
  font-size: 0.7rem;
}

.stats-box-e {
  height: 3rem;
}
</style>
