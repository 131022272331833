<template>
  <router-link :to="`/group/${group}`">
    <div class="group-item">
      <van-row>
        <van-col span="3">
          <div class="group-item-avatar">
            <img v-lazy="avatar" alt="media" />
          </div>
        </van-col>
        <van-col span="19" offset="2">
          <div class="group-item-text">
            <div class="group-item-title">
              <p class="group-item-amount">{{ name }}</p>
              <p class="group-item-time">
                <van-tag
                  v-if="relation === 2"
                  plain
                  color="red"
                  type="primary"
                  closeable
                  @close="closeGroup(1)"
                  >{{ tag }}</van-tag
                >
                <van-tag
                  v-if="relation === 1"
                  plain
                  color="red"
                  type="primary"
                  >{{ tag }}</van-tag
                >
              </p>
            </div>
            <div class="group-item-member">
              <p v-if="member > 0">{{ member }}位成员捐赠<span class="red-text">{{ amount }}</span>元</p>
              <p v-else>暂无成员</p>
            </div>
          </div>
        </van-col>
      </van-row>
      <div class="group-item-message">
        <p v-if="message.length">“{{ message }}”</p>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  data() {
    return {
      tag: null,
    };
  },
  props: {
    group: Number,
    name: String,
    avatar: String,
    amount: Number,
    member: Number,
    time: String,
    message: String,
    leader: String,
    relation: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  mounted() {
    if (this.relation === 1) {
      this.tag = "我参加的";
    }
    if (this.relation === 2) {
      this.tag = "我创建的";
    }
  },
  methods: {
    closeGroup() {
      console.log("close group");
    },
  },
};
</script>

<style>
.group-item {
  min-height: 3rem;
  margin-bottom: 0.2rem;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
}

.group-item p {
  margin: 0;
}

.group-item-text {
  height: 3rem;
}

.group-item-avatar {
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 3rem;
  padding: 0.5rem;
}

.group-item-avatar img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.group-item-title {
  height: 2rem;
}

.group-item-title p {
  line-height: 2rem;
}

.group-item-amount {
  float: left;
  font-size: 0.9rem;
  font-weight: bold;
}

.group-item-time {
  float: right;
  font-size: 0.8rem;
  margin-right: 1rem !important;
}

.group-item-message p {
  font-size: 0.9rem;
  line-height: 1.5rem;
  text-indent: 1rem;
}
</style>
