<template>
  <div class="block-box">
    <div class="block-title">
      <div class="block-title-left">爱心榜</div>
    </div>
    <div class="block-content" ref="test">
      <van-tabs v-model="openTab">
        <van-tab title="个人">
          <div class="message-box" v-if="person.length">
            <van-row>
              <van-col
                span="4"
                v-for="(item, idx) in person"
                :key="idx"
                @click="selectPerson(idx)"
              >
                <div class="message-avatar" ref="person">
                  <img :src="item.avatar" alt="" />
                </div>
              </van-col>
              <!--van-col span="4">
                <div class="message-avatar">
                  <div class="more-button">
                    <svg-icon
                      icon-class="more-right"
                      class-name="more-button-icon"
                    />
                  </div>
                </div>
              </van-col-->
            </van-row>
            <div
              v-if="person.length > 0"
              class="message-pointer"
              ref="personpointer"
            >
              <p>
                <svg-icon icon-class="trg-top" />
              </p>
            </div>
            <div class="message-text" v-if="personItem">
              <p class="message-title">
                {{ personItem.name }}为该项目捐赠{{ personItem.amount }}元
              </p>
              <p class="message-content">{{ personItem.message }}</p>
            </div>
          </div>
          <div v-else>
            <p class="donate-rank-no-data">暂无数据</p>
          </div>
        </van-tab>
        <van-tab title="团队">
          <div class="message-box" v-if="group.length">
            <van-row>
              <van-col
                span="4"
                v-for="(item, idx) in group"
                :key="idx"
                @click="selectGroup(idx)"
              >
                <div class="message-avatar" ref="group">
                  <img :src="item.avatar" alt="" />
                </div>
              </van-col>
              <!--van-col span="4">
                <div class="message-avatar">
                  <div class="more-button">
                    <svg-icon
                      icon-class="more-right"
                      class-name="more-button-icon"
                    />
                  </div>
                </div>
              </van-col-->
            </van-row>
            <div
              v-if="group.length > 0"
              class="message-pointer"
              ref="grouppointer"
            >
              <p>
                <svg-icon icon-class="trg-top" />
              </p>
            </div>
            <div class="message-text" v-if="groupItem">
              <p class="message-title">
                {{ groupItem.name }}为该项目捐赠{{ groupItem.amount }}元
              </p>
              <p class="message-content">{{ groupItem.message }}</p>
            </div>
          </div>
          <div v-else>
            <p class="donate-rank-no-data">暂无数据</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: Array,
      default() {
        return [];
      },
    },
    group: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      personItem: null,
      groupItem: null,
      openTab: 1,
    };
  },
  mounted() {
    if (this.group.length > 0) {
      this.groupItem = this.group[0];
    }
    if (this.person.length > 0) {
      this.personItem = this.person[0];
    }
    this.openTab = Math.round(Math.random());
  },
  methods: {
    selectPerson(idx) {
      let left = idx * 16.5 + 5;
      this.$refs.personpointer.style.marginLeft = left + "%";
      this.personItem = this.person[idx];
    },
    selectGroup(idx) {
      let left = idx * 16.5 + 5;
      this.$refs.grouppointer.style.marginLeft = left + "%";
      this.groupItem = this.group[idx];
    },
    goCreateGroup() {
      this.$parent.changeTab("group");
    },
  },
};
</script>

<style>
.message-box {
  padding: 1rem;
}
.message-avatar {
  width: 3rem;
  height: 3rem;
  margin: 0 auto;
  border-radius: 50%;
  border: solid #ffffff 2px;
}

.message-avatar img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.message-text {
  background-color: antiquewhite;
  border-radius: 0.5rem;
  padding: 1rem;
}

.message-title {
  font-size: 0.8rem;
  line-height: 1.1rem;
}

.message-content {
  font-size: 0.9rem;
}

.message-pointer {
  margin-left: 5%;
}

.message-text p {
  margin: 0;
}

.message-pointer > p {
  width: 1rem;
  font-size: 1.5rem;
  color: antiquewhite;
  line-height: 0rem;
  margin: 0;
  padding: 0;
  margin-bottom: -0.42rem;
}

.more-button-icon {
  font-size: 3rem;
}

.donate-rank-no-data {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}
</style>
