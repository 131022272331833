/* eslint-disable */
function arrayToQuery (arr = {}) {
  let query = ''
  for (let key in arr) {
    let value = arr[key]
    query += `${key}=${value}&`
  }

  return query.slice(0, query.length - 1)
}

module.exports = {
  arrayToQuery: arrayToQuery
}
